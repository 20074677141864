import React, { Fragment } from 'react';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { Button } from "react-bootstrap-buttons";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <Fragment>
      <Navbar sticky="top" bg="white" expand="xl" collapseOnSelect className="navbar--custom frontPage--padding-left frontPage--padding-right row">
        <div className="col-4 navbar__elm--for-small">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Brand href="#pagebeggining" className="col-4 d-flex navbar__elm--for-small  justify-content-center">
          <img
            className="d-fixed mx-auto navbar__small-logo"
            src={require('../assets/images/logo-no-text.png')}
            alt="megaphone man"
          />
        </Navbar.Brand>
        <Navbar.Brand className="col-4 navbar__elm--for-small d-flex justify-content-right pr-0">
          <Link to="/contactUs">
          <Button className="button button--reverse ml-auto navbar__elm--for-not-xs">
            Contact Us
            </Button>
          </Link>
        </Navbar.Brand>
        <Navbar.Brand href="#pagebeggining" className="navbar__elm--for-big mr-4">
          <Logo alt="Sport Compass Logo" className="" />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <br className="navbar__elm--for-small" />
            <HeaderElement href="#benefits">Why Sport Compass <span className="navbar__elm--for-small">&gt;</span></HeaderElement>
            <HeaderElement href="#bringfans">Product <span className="navbar__elm--for-small">&gt;</span></HeaderElement>
            <HeaderElement href="/privacy-policy">Privacy Policy<span className="navbar__elm--for-small">&gt;</span></HeaderElement>
            <br className="navbar__elm--for-small" />
            <br className="navbar__elm--for-small" />
          </Nav>
          <Nav className="ml-auto">
            <HeaderElement className="navbar__elm--for-big" href="https://bar.sportcompass.net">Login</HeaderElement>
            <HeaderElement className="navbar__elm--for-xs" link="/contactUs">Contact Us</HeaderElement>
            <HeaderElement href="tel:+4552604516"><span className="navbar__elm--for-small">Call </span>+ 45 52 60 45 16</HeaderElement>
            <HeaderElement className="navbar__elm--for-small" link="/bookDemo">Book a Demo</HeaderElement>
            <HeaderElement className="navbar__elm--for-small" link="/pricing">Request Pricing</HeaderElement>
            {/* <HeaderElement className="" href="/contactUs">
              <Button className="button button--reverse navbar__elm--for-big">
                Contact Us
                </Button>
            </HeaderElement> */}
            <br className="navbar__elm--for-small" />
            <HeaderElement className="navbar__elm--for-small" href="https://bar.sportcompass.net">Login</HeaderElement>
          </Nav>
          <Navbar.Brand className="navbar__elm--for-big ml-4">
            <Link to="/contactUs">
              <Button className="button button--reverse">
                Contact Us
              </Button>
            </Link>
          </Navbar.Brand>
        </Navbar.Collapse>
      </Navbar>
      {/* </header> */}
      <div className="shadow-layer" />
      <div className="position-relative shadow-hider" />
    </Fragment>
  )
}

function HeaderElement(props) {
  return (
    <Nav.Link href={props.href ? props.href : "#"} className={"my-auto px-4 header-font " + (props.className ? props.className : "")}>
      { props.link ? (
        <Link to={props.link} style={{color: "unset"}}>
          {props.children}
        </Link>
      ) : (
        props.children
      )
      }
    </Nav.Link>
  )
}

export default Header;
