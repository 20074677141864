import React, { useState } from "react";
import { ReactComponent as Logo } from '../assets/images/logo-no-text.svg';
import BarLogos from "../components/barLogos";
import * as api from "../utils/api";
import { useForm } from 'react-hook-form';
import CustomInput from "../components/customInput";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";

function getImage(path) {
  return require('../assets/images/' + path)
}

const ContactUs = () => {
  const { register, handleSubmit, errors } = useForm();
  const [displayMessage, setDisplayMessage] = useState(false);
  const handleClose = () => setDisplayMessage(false);
  const handleShow = () => setDisplayMessage(true);

  const onSubmit = (data) => {
    api.endpoints.sendMessage(data,
      res => {
        handleShow();
      }
    );
  }

  return (
    <div>
      <Modal show={displayMessage} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-1">Message was sent succesfully.</Modal.Title>
        </Modal.Header>
      </Modal>
      <Link to="/">
        <img
          className="contactUs__backArrow"
          src={getImage("back-icon-white.png")}
          alt="back arrow"
        />
      </Link>
      <img
        className="float-left contactUs__image"
        src={getImage("contact-us.png")}
        alt="contact us"
      />
      <div className="float-left contactUs__rightSide contactableArea">
        <Logo className="contactUs__logo" />
        <p className="fs-contact-title contactUs__title">Contact Us</p>
        <p className="fs-1 contactableArea__subText">
          Tell us how we can help you! Our team is here to answer your questions & inquiries.
        </p>
        <form
          className="w-100 contactableArea__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-100 d-flex flex-wrap justify-content-between">
            <CustomInput
              placeholder="First Name"
              name="first_name"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Last Name"
              name="last_name"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Phone Number"
              name="phone_nr"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Email"
              name="email"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Company Name"
              name="company_name"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Country"
              name="country"
              register={register}
              errors={errors}
            />
            {/* <Select
              className="fs-1 custom-input text-left"
              options={countryList().getData()}
              // value={this.state.value}
              // onChange={this.changeHandler}
            /> */}
          </div>
          <textarea
            placeholder="Message"
            className={"default-input w-100 fs-1 d-block" + (errors.message ? " custom_input--error" : "")}
            rows="3"
            name="message"
            ref={register({ required: true })}
          />
          <input
            type="submit"
            className="button button--square no-border-button w-100"
            value="Contact Us"
          />
        </form>
        <BarLogos />
      </div>
    </div>
  )
};

export default ContactUs;