import React, { useContext, useState } from 'react';
import { Button } from "react-bootstrap-buttons";
import { Link } from "react-router-dom";
import Header from '../components/header';
import TextLoop from "react-text-loop";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Divider from './divider';
import DividerContext from '../components/DividerContext';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1200, min: 750 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 1
  }
};
// function FrontPage() {
const FrontPage = () => {
  // const { divider } = useContext(DividerContext);
  // return (
  //   <>
  //     { divider ? (
  //       <Divider />
  //     ) : (
  //       <Page />
  //     )}
  //   </>
  // )

  return <Page />
}

const Page = () => {
  return (
    <div id="pagebeggining" className="page-wrapper">
      <Header />
      <div className="desktop-size--no-display w-100 mobile-size--no-display">
        <img
          className="w-50 mx-auto d-block"
          src={getImage("megaphone-man-small.png")}
          alt="megaphone man"
        />
      </div>
      {/* Megaphone man section */}
      <div className="d-flex justify-content-center frontPage--margin-left frontPage--margin-right">
        <div className="section-1__text tablet-size--center-text flex-shrink-0">
          <h1>Increase <br className="d-none mobile-size--display" />your pub’s{" "}
            <br className="desktop-size--no-display" />
            <TextLoop springConfig={{ stiffness: 70, damping: 31 }}
              adjustingSpeed={500}>
              <span className="word--blue">popularity</span>
              <span className="word--blue">engagement</span>
              <span className="word--blue">sales</span>
            </TextLoop>
          </h1>
          <p className="fs-1" style={{ paddingTop: "37px" }}>
            Easily organise and promote all your live sports and events running at the pub. Find out how you can save time, increase communication with your staff and do free marketing for your pub.
            </p>
          <Link to="/bookDemo">
            <Button
              className="button"
              style={{ marginTop: "21px" }}
            >
              Book a Demo
          </Button>
          </Link>
        </div>
        <div className="section-1__image">
          <img
            className="w-100"
            src={getImage("megaphone-man.png")}
            alt="megaphone man"
          />
        </div>
      </div>
      {/* Logo gallery section */}
      <div
        className="frontPage__barLogos frontPage--padding-left frontPage--padding-right"
      >
        <BarLogo
          name={"pubsport.png"}
          alt={"pubsport"}
          bigAlternative={"pubsport-half-padding.png"}
        />
        <BarLogo
          name={"dubliner.png"}
          alt={"dubliner"}
        />
        <BarLogo
          name={"shamrock.png"}
          alt={"shamrock"}
        />
        <BarLogo
          name={"globe.png"}
          alt={"globe"}
        />
        <BarLogo
          name={"southern-cross.png"}
          alt={"souther-cross"}
        />
        <BarLogo
          name={"freddysbar.png"}
          alt={"freddysbar"}
          bigAlternative={"freddysbar-half-padding.png"}
        />
      </div>
      <div className="d-flex position-relative flex-shrink-0 tablet-size--flex-column tablet-size--align-itmes-center">
        <img
          className="desktop-size--no-display section-2__circles-top"
          src={getImage("half-circles-horizontal.svg")}
          alt="background"
        />
        <div className="section-2__image tablet-size--no-display">
          <img
            className="w-100"
            src={getImage("laptop-image.png")}
            alt="product screenshot"
          />
        </div>
        <div className="section-2__content tablet-size--center-text" >
          <h2 className="w-inherit">This is the Swiss Army knife for sports bars </h2>
          <ul className="text-left fs-1 section-2__list tablet-size--center-text">
            <li><b>View</b> all the upcoming matches and TV channels you can show at the pub</li>
            <li><b>Filter</b> for specific sports, teams or competitions</li>
            <li><b>Add</b> the matches you want to show at the pub</li>
            <li><b>Share</b> the upcoming fixtures to the bar`s website and social media pages</li>
            <li><b>Print</b> the schedule so it's always available for staff</li>
            <li><b>Never</b> forget to update your social media posts or website.</li>
          </ul>
          {/* <Button
            className="float-left button"
          >
            Explore Product
          </Button> */}
        </div>
        <img
          className="float-right position-absolute h-100 tablet-size--no-display"
          alt="background"
          src={getImage("half-circles.svg")}
          style={{
            right: 0,
            top: "0"
          }}
        />
      </div>
      {/* Benefits of automation */}
      <div id="benefits" className="section-benefits frontPage--margin-left frontPage--margin-right">
        <h2 className="text-center">The benefits of automating your pub’s process</h2>
        <div
          className="row flex-shrink-0 align-items-center justify-content-between section-benefits__benefits">
          <BenefitElement
            imgPath="savemoney-icon.png"
            imgAlt="Save money"
            title="Save Money"
            text="50.000 kr, that's how much the bar saves on the work you are currently doing in administration and marketing"
          />
          <BenefitElement
            imgPath="savetime-icon.png"
            imgAlt="Save Time"
            title="Save Time"
            text="Save those 260 hours/ year on finding upcoming fixtures, creating lists, creating posters, posting on Social Media, updating your site and communicating to your staff."
          />
          <BenefitElement
            imgPath="marketing-icon.png"
            imgAlt="Marketing on Autopilot"
            title="Marketing on Autopilot"
            text="Our platform automates your marketing pratices by taking care of your sports scheduling, promotion, and instructs your staff."
          />
          <BenefitElement
            imgPath="attract-icon.png"
            imgAlt="Attract More Guests"
            title="Attract More Guests"
            text="Thousands of people look for places to watch their favourite team play, every week. Let them find you through Sport Compass."
          />
        </div>
        {/* <div
          style={{ "padding-top": "55px" }}
          className="text-center container h-100"
        >
          <div className="row h-100 justify-content-center align-items-center">
            <p
              className=" d-inline"
              style={{
                "font-weight": "bold",
                "font-size": "17px",
                "line-height": "2px",
                color: "#9FCE06"
              }}
            >Learn More</p>
            <img
              style={{
                "height": "14px",
                "padding-left": "5px"
              }}
              src={getImage("arrow-right.svg")}
              alt="arrow right"
            />
          </div>
        </div> */}
      </div>
      <div id="bringfans" className="d-flex justify-content-between sectionCheckmarks frontPage--margin-left frontPage--margin-right">
        <div className="align-self-center">
          <h2 className="mobile-size--center-text">Bringing Sport Fans to Your Pub</h2>
          <div className="border-bottom title--underliner mobile-size--mx-auto" />
          <ul className="text-left fs-1 sectionCheckmarks__list">
            <CheckmarkedElement>One reliable up to date database of matches and TV channels.</CheckmarkedElement>
            <CheckmarkedElement>Save at least 50.000 kr on wages on admin and marketing, a year!</CheckmarkedElement>
            <CheckmarkedElement>Seamless integration with your Social Media channels and website.</CheckmarkedElement>
            <CheckmarkedElement>Setup weeks worth of social media posts in minutes.</CheckmarkedElement>
          </ul>
          {/* </div> */}
          <Link to="/pricing" className="d-block mobile-size--center-text mobile-size--mx-auto">
            <Button
              className="button mobile-size--float-none "
              style={{
                marginTop: "20px"
              }}
            >
              Request Pricing
              </Button>
          </Link>
        </div>
        <img
          className="float-right sectionCheckmarks__image align-self-center"
          src={getImage("Ipad-picture.png")}
          alt="product sreenshot"
        />
      </div>
      <div className="position-relative">
        <img
          className="position-absolute testimonial__background"
          src={getImage("testimonial-background.png")}
          alt="background img"
        />
        <div className="row text-right justify-content-end frontPage--margin-right testimonial__title">
          <div className="col mobile-size--center-text pr-0">
            <h2 className="">
              What our customers are saying
              </h2>
            <div className="border-bottom float-right title--underliner mobile-size--mx-auto" />
          </div>
        </div>
        <div
          style={{
            marginTop: "100px"
          }}
          className="frontPage--margin-left frontPage--margin-right"
        >
          {/* <div className=""> */}

          <Carousel

            partialVisible={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            // ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["desktop"]}
            dotListClass="custom-dot-list-style"
            itemClass="testimonial__testimony-wrapper">
            <TestimonialElement
              image="Karina_Kjær.png"
              name="Karina Kjær"
              position="Pub og Sport"
            >Sport Compass is a very user friendly platform that has optimized our sports program in every way. The customer service is impeccable and the overall evaluation of Sport Compass is very good. I will definitely recommend it.</TestimonialElement>
            <TestimonialElement
              image="Naoise_Mac_Namara.png"
              name="Naoise Mac Namara "
              position="The Shamrock Inn"
            >I met Calin and the Sport Compass fellas when they were just starting out. They pitched the idea and I liked it! It's great to see how they built something starting from a simple idea. Before I had to make time every Monday morning to do the fixtures section for the website and Facebook. Now, I just do it with a few clicks here and there in about five minutes.</TestimonialElement>
            <TestimonialElement
              image="Jakob_Heiberg.png"
              name="Jakob Heiberg"
              position="Gorm`s Pizza"
            >I know Calin and Simon from the Start-up accelerator program ‘Go Grow’ at Copenhagen Business School where they are participating with their exciting company Sport Compass. I am impressed that they have been able to get ‘Save My Local Business’ up and running in such a short time and hope that they will be able to help lots of companies in need in this difficult time.</TestimonialElement>
          </Carousel>
          {/* </div> */}
        </div>
      </div>
      <div
        className="text-center"
        style={{ paddingTop: "130px" }}
      >
        <h2 className="">Get all the fixtures and events on one poster for Free!</h2>
        <p
          style={{ paddingTop: "29px" }}
          className="fs-1"
        >Increase your pub’s popularity today. Get Started.</p>
        <Link to="/bookDemo">
          <Button
            className="button"
            style={{
              marginTop: "20px"
            }}>
            Book a demo
        </Button>
        </Link>
      </div>
      <footer
        className="footer frontPage--padding-left frontPage--padding-right"
      >
        <div className="footer__content w-100 align-middle mobile-size--center-text">
          <div className="footer__logoarea ">
            <img
              src={getImage("logo-white.svg")}
              alt="logo white"
            />
            <p
              className="pt-3 fs-1 font-italic"
            >A Copenhagen Based company that helps pubs grow. <span className="tablet-size--no-display">We provide a Software as a Service tool that automates your marketing process and helps increase your pubs’ popularity.</span></p>
          </div>
          <div className="mobile-size--center-text footer__aboutUsArea">
            <FooterColumn
              title="About Us"
              elements={{
                "Why Sport Compass": "#",
                "Product": "#",
                "Privacy Policy": "/privacy-policy"
              }}
            />
          </div>
          <div className="footer__findUsArea">
            <p className="footer__title footer__findUsArea__title">Find Us</p>
            <a href="https://www.instagram.com/sportcompass_/" className="pr-4 mt-2">
              <img
                style={{ height: "30px" }}
                src={getImage("instagram.svg")}
                alt="instagram"
              />
            </a>
            <a href="https://www.facebook.com/SportCompas/" className="mt-2">
              <img
                style={{ height: "30px" }}
                src={getImage("facebook.svg")}
                alt="facebook"
              />
            </a>
          </div>
        </div>
        <div style={{ marginTop: "25px", opacity: "0.3" }} className="border-bottom row w-100" />
        <div style={{ padding: "17px 0" }} className="row w-100 justify-content-center" >
          © {new Date().getFullYear()} Sport Compass ApS CVR: 39365324 - All Rights Reserved
          </div>
      </footer>
    </div>
  );

}

function FooterColumn(props) {
  return (
    <div
      className=""
    >
      <p className="footer__title">{props.title}</p>
      {Object.keys(props.elements).map((key) => (
        <p key={key}><a className="fs-2" href={props.elements[key]}>
          {key}
        </a></p>
      ))}
    </div>
  )
}

function TestimonialElement(props) {
  return (
    <div className="testimonial__testimony d-flex flex-column justify-content-between">
      <img
        src={getImage("quotationmark.svg")}
        alt="quotationmark"
        className="testimonial__testimony__quotation align-self-start"
      />
      <p className="fs-1 mt-auto mb-auto">
        "{props.children}"
      </p>
      <div className="mt-auto"
      >
        <div className="border-bottom w-100 mb-1" />
        <div className="row align-items-center">
          <img
            // style={{
            //   height: "19px",
            //   paddingRight: "21px"
            // }}
            className="testimonial__testimony__image pr-2"
            src={getImage("testimonial-pics/" + props.image)}
            alt={props.image}
          />
          <div>
            <span className="testimonial__testimony__name">{props.name}</span>
            <br />
            <span>{props.position}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function CheckmarkedElement(props) {
  return (
    <li
      style={{
        marginBottom: "22px",
      }}
      className="d-flex flex-nowrap align-items-center"
    >
      <img
        className="sectionCheckmarks__checkmark"
        src={getImage("checkmark.svg")}
        alt="checkmark"
      />
      <div>{props.children}</div>
    </li>
  )
}

function BenefitElement(props) {
  return (
    <div className="col-xl-auto col-sm-6 col-xs-12 px-0 text-center pb-3 mb-2">
      <div
        style={{

        }}
        className=" section-benefits__element mx-auto">
        <img
          style={{
            height: "102px",
            paddingBottom: "25px"
          }}
          src={getImage('benefits-icons/' + props.imgPath)}
          alt={props.imgAlt}
        />
        <h4>{props.title}</h4>
        <p
          className="fs-1"
          style={{ marginTop: "11px" }}
        >{props.text}</p>
      </div>
    </div>
  )
}

function getImage(path) {
  return require('../assets/images/' + path)
}

function BarLogo(props) {
  return (
    <>
      <img
        className={"frontPage__barLogo " + (props.bigAlternative ? "frontPage__barLogo--onlySmall" : "")}
        src={getImage('bar-logos/' + props.name)}
        alt={props.alt}
      />
      {
        props.bigAlternative &&
        <img
          className="frontPage__barLogo frontPage__barLogo--onlyBig"
          src={getImage('bar-logos/' + props.bigAlternative)}
          alt={props.alt}
        />
      }
    </>
  )
}

export default FrontPage;
