import React, { useContext } from "react";
import { Button } from "react-bootstrap-buttons";
import DividerContext from "../components/DividerContext";


function getImage(name) {
  return require('../assets/images/divider/' + name)
}

const DividerElement = props => {
  return (
    <div
      className="divider__element text-center"
    >
      <img
        className="divider__element__image w-100"
        src={getImage(props.name + ".svg")}
        alt={props.name}
      />
      <div
        className="d-flex flex-column align-items-center justify-content-between divider__element__inner"
      >
        <h2 className="divider__element__title">{props.title}</h2>
        <span className="divider__element__text">{props.text}</span>
        <Button
          className="button divider__element__button"
          onClick={props.onClick}
        >
          Access Website
        </Button>
      </div>
    </div>
  )
}

const Divider = props => {
  const { showDivider } = useContext(DividerContext);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 vw-100">
      <span className="divider__chooseText mb-4">Choose who you are</span>
      <div className="d-flex flex-row divider__elements">
        <DividerElement
          name="fansimg"
          title="Sports Fan:"
          text="I want to watch the game tonight and find other fans to watch it with."
          onClick={() => { window.location.href = 'https://app.sportcompass.net'; }}
        />
        <DividerElement
          name="barsimg"
          title="Bar Owner:"
          text="I want to put my live sports showings on autopilot and increase my bar’s popularity."
          onClick={() => { showDivider(false) }}
        />
      </div>
    </div>
  )
};

export default Divider;