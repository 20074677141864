import React, { useState } from "react";
import { ReactComponent as Logo } from '../assets/images/logo-no-text.svg';
import BarLogos from "../components/barLogos";
import { useForm } from 'react-hook-form';
import CustomInput from "../components/customInput";
import Modal from 'react-bootstrap/Modal';
import * as api from "../utils/api";
import { ReactComponent as ArrowBack } from '../assets/images/back-arrow.svg';
import { Link } from "react-router-dom";

function getImage(path) {
  return require('../assets/images/' + path)
}

const BookDemo = () => {
  const { register, handleSubmit, errors } = useForm();
  const [displayMessage, setDisplayMessage] = useState(false);
  const handleClose = () => setDisplayMessage(false);
  const handleShow = () => setDisplayMessage(true);

  const onSubmit = (data) => {
    api.endpoints.bookDemo(data,
      res => {
        handleShow();
      }
    );
  }

  return (
    <div>
      <Modal show={displayMessage} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-1">Message was sent succesfully.</Modal.Title>
        </Modal.Header>
      </Modal>
      <img
        className="float-right demo__image"
        src={getImage("book-demo.png")}
        alt="contact us"
      />
      <div className="float-left demo__contactableArea contactableArea" >
        <Logo className="demo__logo" />
        <Link to="/">
          <ArrowBack
            className="d-block demo__arrow"
          />
        </Link>
        <p className="fs-contact-title demo__title">Book A Demo</p>
        <p className="fs-1 contactableArea__subText text-left">
          Automate your marketing process today. Our tool activates your online and drives traffic to your bars in real time. Get started with a quick demo to find out more.
        </p>
        <form
          className="w-100 contactableArea__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-100 d-flex flex-wrap justify-content-between">
            <CustomInput
              placeholder="First Name"
              name="first_name"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Last Name"
              name="last_name"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Phone Number"
              name="phone_nr"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Email"
              name="email"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Company Name"
              name="company_name"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Country"
              name="country"
              register={register}
              errors={errors}
            />
            {/* <Select
              className="fs-1 custom-input text-left"
              options={countryList().getData()}
              // value={this.state.value}
              // onChange={this.changeHandler}
            /> */}
          </div>
          {/* <input
              placeholder="Reason"
              className="fs-1 custom-input w-100"
          /> */}
          <input
            type="submit"
            className="button button--square no-border-button w-100"
            value="Book a Demo"
          />
        </form>
        <BarLogos />
      </div>
    </div>
  )
};

export default BookDemo;