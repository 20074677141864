import React, { useState } from "react";
import { ReactComponent as Logo } from '../assets/images/logo-no-text.svg';
import { ReactComponent as ArrowBack } from '../assets/images/back-arrow.svg';
import BarLogos from "../components/barLogos";
import { useForm } from 'react-hook-form';
import CustomInput from "../components/customInput";
import Modal from 'react-bootstrap/Modal';
import * as api from "../utils/api";
import { Link } from "react-router-dom";

function getImage(path) {
  return require('../assets/images/' + path)
}

const Pricing = () => {
  const { register, handleSubmit, errors } = useForm();
  const [displayMessage, setDisplayMessage] = useState(false);
  const handleClose = () => setDisplayMessage(false);
  const handleShow = () => setDisplayMessage(true);

  const onSubmit = (data) => {
    api.endpoints.requestPricing(data,
      res => {
        handleShow();
      }
    );
  }

  return (
    <div>
      <Modal show={displayMessage} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-1">Message was sent succesfully.</Modal.Title>
        </Modal.Header>
      </Modal>
      <Link to="/">
        <img
          className="contactUs__backArrow"
          src={getImage("back-icon-white.png")}
          alt="back arrow"
        />
        <ArrowBack
          className="contactUs__backArrow d-none mobile-size--display"
        />
      </Link>
      <img
        className="float-left pricing__image"
        src={getImage("pricing.png")}
        alt="pricing"
      />
      <img
        className="float-right pricing_image--mobile"
        src={getImage("pricing-mobile.png")}
        alt="pricing"
      />
      <div className="float-left contactUs__rightSide contactableArea">
        <Logo className="contactUs__logo" />
        <p className="fs-contact-title contactUs__title">Get Pricing</p>
        <p className="fs-1 contactableArea__subText">
          We offer an yearly subscription based on the number of events, your location and company size. Get a Quota today.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-100 contactableArea__form"
        >
          <CustomInput
            placeholder="Email"
            name="email"
            register={register}
            errors={errors}
            extraClasses="w-100"
          />
          <div className="w-100 d-flex flex-wrap justify-content-between">
            <CustomInput
              placeholder="First Name"
              name="first_name"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Last Name"
              name="last_name"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Phone Number"
              name="phone_nr"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Company Name"
              name="company_name"
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Number of Employees"
              name="number_employees"
              minLength={1}
              register={register}
              errors={errors}
            />
            <CustomInput
              placeholder="Country"
              name="country"
              register={register}
              errors={errors}
            />
            {/* <Select
              className="fs-1 custom-input text-left"
              options={countryList().getData()}
              // value={this.state.value}
              // onChange={this.changeHandler}
            /> */}
          </div>
          <input
            type="submit"
            className="button button--square no-border-button w-100"
            value="Request Pricing"
          />
        </form>
        <BarLogos />
      </div>
    </div>
  )
};

export default Pricing;