import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "bootstrap/dist/css/bootstrap.css";
import "lato-font/css/lato-font.css";
import "./assets/scss/index.scss";
import axios from "axios";

var baseURL 

if (process.env.NODE_ENV !== "production")
  { 
  baseURL = "http://localhost:3000";
  } 
else
  { 
    if (process.env.REACT_APP_API_HOST) {
      baseURL =  process.env.REACT_APP_API_HOST;
    } else {
      baseURL = "https://app.sportcompass.net";
    }
  }
    
axios.defaults.baseURL = baseURL + "/user_api/inquiries/";
   

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
