import React from "react";

const BarLogos = () => {
  return (
    <div className="barLogo" >
      <div
        className="d-flex justify-content-between"
        style={{
          marginBottom: "10px"
        }}
      >
        <img
          className="barLogo__bar"
          src={getLogo('dubliner.png')}
          alt="dubliner"
        />
        <img
          className="barLogo__bar"
          src={getLogo('globe.png')}
          alt="globe"
        />
        <img
          className="barLogo__bar"
          src={getLogo('freddysbar.png')}
          alt="petanque"
        />
      </div>
      <div className="d-flex justify-content-between">
        <img
          className="barLogo__bar"
          src={getLogo('shamrock.png')}
          alt="shamrock"
        />
        <img
          className="barLogo__bar"
          src={getLogo('southern-cross.png')}
          alt="southern-cross"
        />
        <img
          className="barLogo__bar"
          src={getLogo('pubsport.png')}
          alt="pubsport"
        />
      </div>
    </div>
  );
}

function getLogo(path) {
  return require('../assets/images/bar-logos/' + path)
}

export default BarLogos;