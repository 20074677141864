import React, { useState } from 'react';
import './App.css';
import ContactUs from './pages/contactUs';
import BookDemo from './pages/bookDemo';
import { Route, BrowserRouter, Switch } from "react-router-dom";
import FrontPage from './pages/frontPage';
import Pricing from './pages/pricing';
import DividerContext from './components/DividerContext';
import PrivacyPolicy from './pages/privacyPolicy'

function App() {
  const [divider, showDivider] = useState(true);
  const value = { divider, showDivider };

  return (
    <DividerContext.Provider value={value}>
      <BrowserRouter>
        <Switch>
          <Route path="/contactUs" component={ContactUs} />
          <Route path="/bookDemo" component={BookDemo} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/" component={FrontPage} />
        </Switch>
      </BrowserRouter>
    </DividerContext.Provider>
  );
}

export default App;
