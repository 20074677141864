import React from "react";

const CustomInput = ({ placeholder, name, register, errors, extraClasses, minLength }) => (
  <input
    placeholder={placeholder}
    className={"fs-1 custom-input" + 
                (errors[name] ? " custom_input--error" : "") +
                (extraClasses ? (" " + extraClasses) : "")
              }
    name={name}
    ref={register({ 
      required: true, 
      minLength: minLength ? minLength : 2
    })}
  />
)  

export default CustomInput;