import axios from "axios";

export const urls = {
  sendMessage: "send_message",
  bookDemo: "book_demo",
  requestPricing: "request_pricing"
};


export const endpoints = {
  sendMessage: async (object, cb) => {
    try {
      let res = await axios.post(urls.sendMessage, object);
      cb(res);
    } catch (ex) {
      console.log(ex);
      console.log(ex.response.data.message);
    }
  },
  bookDemo: async (object, cb) => {
    try {
      let res = await axios.post(urls.bookDemo, object);
      cb(res);
    } catch (ex) {
      console.log(ex);
      console.log(ex.response.data.message);
    }
  },
  requestPricing: async (object, cb) => {
    try {
      let res = await axios.post(urls.requestPricing, object);
      cb(res);
    } catch (ex) {
      console.log(ex);
      console.log(ex.response.data.message);
    }
  }
}